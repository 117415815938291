export const btnObj = {
  need: "I Have A Need",
  contributeResources: "Contribute Resources",
  contributeYourResources: "Contribute Your Resources",
  DetailedDescription: "More",
  SWAP: "SWAP",
  download: "Download",
  jumpTo: "Go to",
  ios: "IOS",
  android: "Android",
};
