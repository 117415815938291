export const titleObj = {
  languageTitle: "Language",
  nodeDeployment: "Node Deployment",
  scriptTitle: "Script",
  scriptSubTitle: "Download and use scripts.",
  homeDecentralizationSubTitle: "Decentralized Edge Computing Infrastructure Network",
  homeDecentralizationTitle: "Infrastructure Network Decentralized Edge Computing<span class='title-color'> DECN </span>",
  homeDecentralizationChildSubTitle:
    "DECN is a edge computing network composed of decentralized computing resources, which provides computing resources, bandwidth resources and storage resources to resource users to obtain network incentives.",
  homeDecentralizationChildTitle: "What Is<span class='title-color'> DECN ?</span>",
  homeAdoptTitle: "Content Delivery Through<span class='title-color'> DECN </span>Global Network",
  homeAdoptSubTitle: "Achieve high-performance content delivery through the DECN global network.",
  homeAdoptSetion: [
    {
      Icon: require("@/assets/images/home_icon_globe.png"),
      img: "",
      title: "Modern Digital Experience",
      desc: "The project itself does not require any collateral or cumbersome application process, only the storage, calculation, and network of contribution nodes.",
    },
    {
      Icon: require("@/assets/images/user-check.png"),
      img: require("@/assets/images/home_img_expansion.png"),
      title: "Easy Expansion",
      desc: "Improve efficiency and support future development by processing workloads closer to the end user.",
    },
    {
      Icon: require("@/assets/images/home_icon_shield.png"),
      img: require("@/assets/images/home_img_secure.png"),
      title: "App Security",
      desc: "Relieve web application attacks and vulnerabilities through comprehensive security controls, unified policies, and observability (including simplified deployment and management of cross environment application security protection functions).",
    },
  ],
  homeProductTitle: "Product Overview",
  homeResourceList: [
    {
      img: require("@/assets/images/home_logo_icdn.png"),
    },
    {
      img: require("@/assets/images/home_logo_ppio.png"),
    },
    {
      img: require("@/assets/images/home_logo_bih.png"),
    },
    {
      img: require("@/assets/images/home_logo_tiktok.png"),
    },
    {
      img: require("@/assets/images/home_logo_iqiyi.png"),
    },
    {
      img: require("@/assets/images/home_logo_youja.png"),
    },
    {
      img: require("@/assets/images/home_logo_keyword.png"),
    },
  ],

  homeProductSetion: [
    {
      title: "Improving Cross Environment Content Delivery",
      desc: "DECN distributed cloud CDN is built on a high-performance and secure global dedicated network, providing end users with a rich digital experience. The integration of distributed cloud CDN and critical application security services provides strong support for enterprises to implement multi cloud and edge based plans.",
    },
    {
      title: "DECN Global PoP Nodes",
      desc: "Manage and protect application workloads and cached content from nodes (PoPs) on DECN's global network.",
    },
  ],
  homeCoreTitle: "Core Functions",
  homeCoreSubTitle: "To create a rich digital experience for end-users and provide strong support for enterprises to implement multi cloud and edge based plans",
  homeCoreSetion: [
    {
      topTitle: "Secure",
      title: "Secure Multi cloud And Edge Application Delivery",
      desc: "Utilize integrated security features, including Web Application Firewall (WAF), Distributed Denial of Service (DDoS) mitigation measures, API security, and Bot defense. These features are provided by platforms built specifically for edge workloads based on multi cloud and microservices.",
    },
    {
      topTitle: "Universal",
      title: "Global availability",
      desc: "Efficient global delivery is achieved through a high-capacity DECN global network, and DECN's DNS and load balancer services are utilized to balance traffic between multiple source servers across public and private clouds, thereby achieving high availability.",
    },
    {
      topTitle: "Simple",
      title: "Centralized control plane",
      desc: "By observing end-to-end application traffic and events, reduce operational complexity, optimize application efficiency, and improve application security efficiency.",
    },
    {
      topTitle: "Optimized",
      title: "Easy to configure cache strategy",
      desc: "Obtain cache policies that are easy to configure and use. Distributed cloud CDN only caches the content required by the source to avoid bias.",
    },
    {
      topTitle: "Stay up-to-date",
      title: "Support for modern content",
      desc: "Obtain support for modern content types, including on-demand videos, streaming media, websites, and other content required for API traffic. Optimize performance based on file type.",
    },
    {
      topTitle: "Cloud",
      title: "Cloud agnostic",
      desc: "Benefiting from the higher flexibility of cross public and hybrid cloud solutions, fewer deployment limitations of application security protection functions, stronger traffic routing control, and the ability to instruct workload deployment locations.",
    },
  ],
  homeResourceTitle: "Resource",
  homeResourceList: [
    {
      img: require("@/assets/images/home_logo_icdn.png"),
    },
    {
      img: require("@/assets/images/home_logo_ppio.png"),
    },
    {
      img: require("@/assets/images/home_logo_bih.png"),
    },
    {
      img: require("@/assets/images/home_logo_tiktok.png"),
    },
    {
      img: require("@/assets/images/home_logo_iqiyi.png"),
    },
    {
      img: require("@/assets/images/home_logo_youja.png"),
    },
    {
      img: require("@/assets/images/home_logo_keyword.png"),
    },
  ],

  contributeResourcesRewardsTitle: "Contribute Your Idle Bandwidth  To Receive<span class='title-color'> PIN </span>Rewards",
  contributeResourcesWhatTitle: "What Is<span class='title-color'> PIN TOKEN </span>？",
  contributeResourcesWhatSubTitle: "What Is<span class='title-color'> PIN TOKEN </span>？",
  contributeResourcesWhatSubDesc:
    "PIN TOKEN is the contribution reward TOKEN of the DECN network ecosystem, generated using the Burn and Mint Equity (BME) model. It is used for rewards, governance, trading, and pledging.",
  contributeResourcesWhatSubTitle1: "PIN TOKEN Economic Model",
  contributeResourcesWhatSubDesc1:
    "There is no upper limit to the total number of PIN TOKENs, and their generation will be based on the actual coin minting of DECN network resources consumed by the partner. That is, if the partner consumes 100 USDT of DECN network fees, they need to pay 100 USDT of PIN TOKEN to DECN network combustion, and the market will generate 100 USDT of PIN TOKEN accordingly.<br />The Burn and Mint Equilibrium (BME) model for deflation. The minting coefficient for each access network is halved annually, with a coefficient of 1 in the first year. This means that the partner burns a PIN Token and mints a PIN Token to the market; In the second year, for every PIN token burned by the partner, 0.5 PIN tokens were cast.",
  contributeResourcesGetTitle: "How To Obtain<span class='title-color'> PIN TOKEN </span>",
  contributeResourcesGetSubTitle: "Provide Computing Power To DECN Networks",
  contributeResourcesGetSubTitle2: "Obtained Through SWAP Transactions",

  dashboardChartTitle: "Market cap",
  dashboardChartList: [
    {
      title: "Total txns",
      value: "61.89M",
      color: "#5686E6",
    },
    {
      title: "Active validators",
      value: "180",
      color: "#5686E6",
    },
    {
      title: "Total addresses",
      value: "2,660,774",
      color: "#ffffff",
    },
    {
      title: "Total assets",
      value: "3,774",
      color: "#5686E6",
    },
    {
      title: "Block height",
      value: "19836369",
      color: "#ffffff",
    },
  ],

  nodeName: {
    // 地图节点名称
    Malaysia: "马来西亚节点",
    Thailand: "泰国节点",
    Indonesian: "印尼节点",
    Philippine: "菲律宾节点",
    Vietnam: "越南节点",
    Japan: "日本节点",
  },

  deploymentNoticeList: [
    {
      title: "DECN Token Allocation Plan",
      list: [
        {
          subtitle: "PAGE",
          title: "DECN Token Allocation Ratio",
          mode: "allocationRatio",
        },
        {
          subtitle: "PAGE",
          title: "DECN token allocation rules",
          mode: "allocationRules",
        },
        {
          subtitle: "PAGE",
          title: "DECN Project Roadmap (2023-2025)",
          mode: "projectRoadmap",
        },
      ],
    },
    {
      title: "PIN TOKEN",
      list: [
        {
          subtitle: "PAGE",
          title: "The generation and allocation of PIN TOKEN",
          mode: "generationAndAllocation",
        },
        {
          subtitle: "PAGE",
          title: "The purpose of PIN TOKEN",
          mode: "purpose",
        },
        {
          subtitle: "PAGE",
          title: "How to participate in PIN TOKEN node construction",
          mode: "nodeConstruction",
        },
        {
          subtitle: "PAGE",
          title: "How to claim PIN TOKEN and conduct point transactions",
          mode: "pointsTrading",
        },
        {
          subtitle: "PAGE",
          title: "Apply for a super node",
          mode: "superNode",
        },
      ],
    },
    {
      title: "Ubuntu22.04 LTS Installation Tutorial",
      list: [
        {
          subtitle: "PAGE",
          title: "Create installation media",
          mode: "install",
        },
        {
          subtitle: "PAGE",
          title: "Bind the machine to your own account",
          mode: "binding",
        },
      ],
    },
  ],

  // 节点部署详情--------
  nodeDeploymentMenu: [
    {
      id: "0",
      title: "DECN Token Allocation Plan",
      list: [
        {
          cid: "0",
          title: "DECN Token Allocation Ratio",
          mode: "allocationRatio",
        },
        {
          cid: "1",
          title: "DECN token allocation rules",
          mode: "allocationRules",
        },
        {
          cid: "2",
          title: "DECN Project Roadmap (2023-2025)",
          mode: "projectRoadmap",
        },
      ],
    },
    {
      id: "2",
      title: "PIN TOKEN",
      list: [
        {
          cid: "0",
          title: "The generation and allocation of PIN TOKEN",
          mode: "generationAndAllocation",
        },
        {
          cid: "1",
          title: "The purpose of PIN TOKEN",
          mode: "purpose",
        },
        {
          cid: "2",
          title: "How to participate in PIN TOKEN node construction",
          mode: "nodeConstruction",
        },
        {
          cid: "3",
          title: "How to claim PIN TOKEN and conduct point transactions",
          mode: "pointsTrading",
        },
        {
          cid: "4",
          title: "Apply for a super node",
          mode: "superNode",
        },
      ],
    },
    {
      id: "3",
      title: "Ubuntu22.04 LTS Installation Tutorial",
      list: [
        {
          cid: "0",
          title: "Create installation media",
          mode: "install",
        },
        {
          cid: "1",
          title: "Bind the machine to your own account",
          mode: "binding",
        },
      ],
    },
  ],
  // DECN代幣分配比例
  allocationRatio: {
    title: "DECN token allocation ratio",
    list: [
      {
        title: "1.Financing (10%)",
        desc: `·Purpose: This portion of tokens will be used for initial financing activities to support the launch and development of the network. Financing may be conducted through private or public offering channels to attract early investors to support the project.<br />
        ·Usage: The funds are mainly used for developing DECN technology infrastructure, preliminary market promotion, team building, and initial operating costs.`,
      },
      {
        title: "2.DECN Laboratory (10%)",
        desc: `·Purpose: Specially used for research and development investment to support the technological innovation and new function development of DECN laboratory.<br />
          ·Usage: These tokens will fund future research projects, technological improvements, new computing development, and security enhancements.  `,
      },
      {
        title: "3.PL team and contributors (10%)",
        desc: `·Purpose: To reward the founding team and key contributors of the project to recognize their efforts and contributions in the early stages of the project.<br />
          ·Usage: Ensure long-term participation and motivation of team members and early contributors, maintain their enthusiasm and loyalty.`,
      },
      {
        title: "4.Foundation (5%)",
        desc: `·Purpose: To support the daily operations and management activities of DECN Foundation, which is responsible for the governance and strategic planning of the entire network.<br />
          ·Use: Funds are used to organize community activities, legal advisors, external audits, and other online support and maintenance tasks.`,
      },
      {
        title: "5.Ecological development (5%)",
        desc: `·Purpose: To expand and strengthen the DECN ecosystem, including collaboration with partners, community building, marketing, and user education.<br />
          ·Usage: Motivate developers and content creators to use DECN networks, fund marketing activities and community reward programs, enhance user engagement and online activity.`,
      },
      {
        title: "6.Governance mining (60%)",
        desc: `·Purpose: To distribute most of the tokens through governance mining mechanisms to encourage and reward community members for their participation and contribution to the network.<br />
          ·Usage: Participants can obtain tokens by maintaining the network, participating in governance voting, providing resources, and completing specific tasks. This pipeline aims to continuously stimulate the growth and healthy operation of the network.`,
      },
    ],
    subTitle: "Management and supervision",
    subTitleDesc:
      "Token allocation will be executed through smart contracts to ensure transparency and fairness. All allocation activities will be recorded on the blockchain for public auditing and verification.",
  },
  // DECN代幣分配規則
  allocationRules: {
    title: "DECN token allocation rules",
    list: [
      {
        title: "Investor's token release strategy",
        list: [],
        desc: `·Distribution period: The $DECN tokens allocated by investors will be released in equal amounts within three years.<br />
          ·Release mechanism: The transfer restriction of $DECN tokens starts from the 13th month after the initial allocation date and is released in equal amounts every month, with a total of 24 releases until the end of the 36th month.<br />
          ·Purpose: This strategy aims to provide long-term incentives for investors while preventing premature market saturation and ensuring the stability of token prices.`,
      },
      {
        title: "Token release strategy for employees and core members",
        list: [],
        desc: `·Allocation deadline: The $DECN tokens allocated to employees and core members will be released in equal amounts within four years.<br />
          ·Release mechanism: The transfer restriction of $DECN tokens starts from the 13th month after the initial allocation date and is released in equal amounts every month, with a total of 36 releases until the end of the 48th month.<br />
          ·Purpose: This method encourages long-term commitment and participation from employees and core team members, which helps maintain team stability and project continuity.  `,
      },
      {
        title: "A pipeline for earning $DECN",
        desc: "",
        list: [
          {
            subTitle: "1.Provide hardware support:",
            subDesc: `·Users can earn hourly availability rewards by providing hardware resources to the network and maintaining their availability.<br />
              ·This encourages users to invest high-quality hardware resources, enhancing the overall efficiency and reliability of the network. `,
          },
          {
            subTitle: "2.Pledge $DECN:",
            subDesc: `·Users can place $DECN as collateral on nodes in the network to earn hourly staking rewards.<br />
              ·This adds security to the network, as pledge mechanisms are usually positively correlated with node behavior, helping to ensure the honesty and reliability of node operations. `,
          },
          {
            subTitle: "3.Burning CDN points to exchange for $DECN:",
            subDesc: `·Purpose: To expand and strengthen the DECN ecosystem, including collaboration with partners, community building, marketing, and user education.<br />
              ·Usage: Motivate developers and content creators to use DECN networks, fund marketing activities and community reward programs, enhance user engagement and online activity. `,
          },
        ],
      },
    ],
  },
  // DECN項目路線圖（2023-2025）
  projectRoadmap: {
    title: "DECN Project Roadmap (2023-2025)",
    list: [
      {
        year: "2023",
        yearList: [
          {
            title: "Q2",
            clist: [
              {
                ctitle: "Project Definition and Strategy Planning",
                desc: `·Establish project goals, key milestones, and management structure.<br />
                  ·Start developing a technical white paper and detailed architecture design.`,
              },
              {
                ctitle: "Technology pre research and platform design",
                desc: `·Define network communication protocols and node operation specifications.<br />
                ·Complete preliminary network framework development.`,
              },
            ],
          },
          {
            title: "Q3",
            clist: [
              {
                ctitle: "Development and testing environment setup",
                desc: `·Build a development environment and complete internal testing of the basic network.<br />
                  ·Invite preliminary partners to participate in closed testing.`,
              },
              {
                ctitle: "Community construction and market promotion",
                desc: `·Establish official social media platforms and community forums.<br />
                  ·Release project progress updates to attract early community members.`,
              },
            ],
          },
          {
            title: "Q4",
            clist: [
              {
                ctitle: "Public testing and echo integration",
                desc: `·Release a publicly tested version of DECN network and widely collect user feedback.<br />
                  ·Perform necessary network tuning and problem fixing based on feedback.`,
              },
              {
                ctitle: "Expand partner network",
                desc: `·Sign cooperation agreements with key content and resource providers.<br />
                  ·Explore the possibility of cooperation with industry leaders and expand influence.`,
              },
            ],
          },
        ],
      },
      {
        year: "2024",
        yearList: [
          {
            title: "Q1",
            clist: [
              {
                ctitle: "Test network stage ",
                desc: `·Start the testing network of DECN network and conduct extensive performance and security testing.<br />
                  ·Invite more developers and users to participate in testing to ensure the stability and reliability of the network.<br />
                  ·Continuously optimize network architecture and functionality based on test results.`,
              },
              {
                ctitle: "User education and training",
                desc: `·Open online seminars and training courses to educate users and developers on how to effectively use DECN networks.<br />
                    ·Publish detailed technical documentation and usage guidelines.`,
              },
            ],
          },
          {
            title: "Q2",
            clist: [
              {
                ctitle: "Preparation for official network launch",
                desc: `·Based on the feedback from Q1's testing network, complete the final network optimization and adjustment.<br />
                  ·Prepare for the global launch and operation of the official network.`,
              },
              {
                ctitle: "Strengthen marketing activities",
                desc: `·Increase marketing efforts to ensure user acceptance and visibility during network launch.<br />
                    ·Participate in international technology conferences and exhibitions, showcasing DECN technology and application cases.`,
              },
            ],
          },
          {
            title: "Q3-Q4",
            clist: [
              {
                ctitle: "Formal network operation and expansion",
                desc: `·Formally launch DECN network, monitor network operation status, and ensure global service availability.<br />
                  ·Continuously expand network functions and services, improve user experience and network efficiency.`,
              },
              {
                ctitle: "Deepening Ecosystem Construction and Cooperation",
                desc: `·Strengthen cooperation with industry partners and jointly expand the ecosystem.<br />
                  ·Encourage and support collaborative developers to innovate on the DECN platform.`,
              },
            ],
          },
        ],
      },
      {
        year: "2025",
        yearList: [
          {
            title: "Continuous growth and innovation",
            clist: [
              {
                ctitle: "Technological innovation and ecological development ",
                desc: `·Continuously developing new features, such as enhancing security measures and data processing capabilities.<br />
                  ·Expand business scope in global markets, especially emerging markets.`,
              },
              {
                ctitle: "Strengthening the relationship between community and market",
                desc: `·Enhance interaction with users and customers, provide high-quality customer support, and maintain high user satisfaction.<br />
                  ·Participate in and influence the formulation of relevant standards both within and outside the industry.`,
              },
            ],
          },
        ],
      },
    ],
  },

  // PIN TOKEN的生成與分配
  generationAndAllocation: {
    title: "The generation and allocation of PIN TOKEN",
    list: [
      {
        title: "Integral generation",
        desc: "The generation of integrals is based on the contribution to the network, which includes the following aspects:",
        clist: [
          {
            ctitle: "1.Bandwidth contribution:",
            cdesc: `The bandwidth provided by nodes is the main factor in integral generation. More bandwidth contribution means greater ability to support content transmission, thus earning more points.`,
          },
          {
            ctitle: "2.Data storage and processing:",
            cdesc: `Storing and processing data online is also a way to obtain points. Nodes can earn points based on the amount of data they store and process.`,
          },
          {
            ctitle: "3.Network stability and availability:",
            cdesc: `Nodes that continuously provide stable and reliable services typically receive additional point rewards to motivate them to maintain high-quality network services.`,
          },
        ],
      },
      {
        title: "Integral allocation",
        desc: "The distribution mechanism of points should ensure fairness and incentive compatibility. The following are the distribution strategies:",
        clist: [
          {
            ctitle: "1.Fixed ratio allocation",
            cdesc: `·Definition: Nodes earn points based on their actual contribution in bandwidth and processing power. This method rewards nodes at a fixed ratio, ensuring that contributions are directly linked to rewards.<br />
              ·Example: For example, for every 1TB of data transmission capacity provided, a node can obtain a fixed number of PIN TOKENs.`,
          },
          {
            ctitle: "2.Dynamic adjustment",
            cdesc: `·Purpose: To dynamically adjust the allocation ratio of points based on the total demand and load of the network. This can flexibly respond to different network states and motivate nodes to provide more resources during peak demand periods.<br />
              ·Implementation: During peak network traffic, the system automatically increases the distribution rate of points to reward nodes that can provide additional resources.`,
          },
          {
            ctitle: "3.Performance benchmark",
            cdesc: `·Standard setting: Set performance benchmarks, only nodes that meet or exceed these benchmarks can earn points. This prompts nodes to maintain their devices and connections, ensuring the provision of high-quality services.<br />
            ·Purpose: Encourage node operators to invest in equipment maintenance and upgrades to maintain their service level in compliance with network requirements.`,
          },
          {
            ctitle: "4.Participation time limit",
            cdesc: `·Reward mechanism: Consider the active time of nodes in the network and provide additional point rewards for long-term service nodes to recognize their continuous contributions and loyalty.<br />
              ·Implementation: For example, nodes that have been running continuously for more than one year will receive an additional 5% points reward per month.`,
          },
          {
            ctitle: "5.Participation time",
            cdesc: `·Definition: Considering network maintenance and machine wear and tear, devices connected to the network will have their reward points halved every 6 months starting from the day of connection.<br />
              ·Recovery mechanism: Participating nodes can recover to their initial computing power by consuming the PIN TOKEN pipeline. This mechanism aims to motivate nodes to continuously update and maintain their hardware to maintain good service quality.`,
          },
        ],
      },
      {
        title: "Incentive and punishment mechanisms",
        desc: "",
        clist: [
          {
            ctitle: "1.Incentives:",
            cdesc: `For nodes that perform well or are responsible for important tasks in the network, additional point rewards are given, such as the ability to quickly transmit critical content or handle large amounts of requests.. More bandwidth contribution means greater ability to support content transmission, thus earning more points.`,
          },
          {
            ctitle: "2.Punishment:",
            cdesc: `If a node experiences service interruption or does not reach the expected service level, a portion of its points may be deducted as a mandatory measure to maintain service quality`,
          },
        ],
      },
      {
        title: "Audit and transparency",
        desc: "",
        clist: [
          {
            ctitle: "1.Transparent records:",
            cdesc: `The generation and allocation records of all points are open and transparent, allowing all participants within the network to verify and supervise.`,
          },
          {
            ctitle: "2.Regular audits:",
            cdesc: `Regularly conduct online audits to ensure that the points distribution mechanism is fair and unbiased, and is executed in accordance with established rules.`,
          },
        ],
      },
    ],
  },

  // PIN TOKEN的用途
  purpose: {
    title: "The purpose of PIN TOKEN",
    desc: "PIN PIN TOKEN plays multiple important roles in distributed content delivery networks (DECNs), with applications covering various aspects such as incentive mechanisms and transaction media.",
    list: [
      {
        title: "Pay for network service fees",
        desc: `The most basic use of PIN TOKEN is as a medium for payment of service fees in the network. This includes:`,
        clist: [
          {
            ctitle: "1.Bandwidth cost:",
            cdesc: `Content providers need to pay PIN TOKEN to compensate for the nodes that provide bandwidth for their content transmission.`,
          },
          {
            ctitle: "2.Data storage cost:",
            cdesc: `Storing data also requires resources, therefore, in order to store content on network nodes, content providers may need to pay the corresponding PIN TOKEN.`,
          },
          {
            ctitle: "3.Value added services:",
            cdesc: `For example, services such as content acceleration, enhanced security, and priority processing that may be provided in content distribution networks can also be purchased through PIN TOKEN.`,
          },
        ],
      },
      {
        title: "Motivate participants",
        desc: `PIN TOKEN serves as an incentive mechanism to encourage network participants (especially node operators) to provide higher quality services:`,
        clist: [
          {
            ctitle: "1.Performance rewards:",
            cdesc: `Nodes receive PIN TOKEN rewards based on their provided bandwidth quality, data processing speed, and network stability.`,
          },
          {
            ctitle: "2.Participation rewards:",
            cdesc: `Encourage new nodes to join the network and provide resources, and initially give a certain PIN TOKEN as a startup reward.`,
          },
        ],
      },
      {
        title: "Trading and redemption",
        desc: `PIN TOKEN can trade within the internal market of the network or with other cryptocurrencies:`,
        clist: [
          {
            ctitle: "1.Exchange to other currencies:",
            cdesc: `Users can choose to exchange PIN TOKEN for Bitcoin, Ethereum, or even fiat currency on cryptocurrency exchanges.`,
          },
          {
            ctitle: "2.Internal transactions:",
            cdesc: `Services or resources in the network can be purchased or exchanged through PIN TOKEN, such as bandwidth transactions between users.`,
          },
        ],
      },
      {
        title: "Enhance network security",
        desc: `PIN TOKEN can also be used as a deposit or collateral to enhance the reputation and security of the network:`,
        clist: [
          {
            ctitle: "1.Margin mechanism:",
            cdesc: `Nodes may need to deposit a certain amount of PIN TOKENs as a security deposit to prove their commitment to the network.`,
          },
          {
            ctitle: "2.To prevent abuse and fraudulent behavior:",
            cdesc: `Use PIN TOKEN as collateral for the transaction to ensure that both parties fulfill their obligations in the transaction.`,
          },
        ],
      },
    ],
  },

  // 如何参与PIN TOKEN节点建设
  nodeConstruction: {
    title: "How to participate in PIN TOKEN node construction",
    list: [
      {
        title: "1.Understanding network requirements",
        desc: `Before participating, it is necessary to first understand the basic requirements of the network for nodes, including hardware settings, network connection speed, availability (uptime), etc. Generally speaking, a CDN node needs to have a stable high-speed internet connection, sufficient storage space to cache content, and a certain amount of computing power to process data requests.`,
      },
      {
        title: "2.Obtain the required hardware",
        desc: `According to the technological requirements of the network, purchase or prepare necessary hardware devices. This may include high-performance servers, stable network devices (such as routers and switches), and sufficient hard disk storage. To ensure the reliability of services, it may also be necessary to consider uninterrupted power supply systems (UPS) and good physical security measures.`,
      },
      {
        title: "3.Install and package software",
        desc: `Download and install the required software, which typically includes node operating systems, network management software, and specific CDN software. These software will help you connect to CDN networks, manage data transmission, and cache content. During the packaging process, appropriate security measures may need to be set up, such as firewalls and data encryption.`,
      },
      {
        title: "4.Connect to the network",
        desc: `Connect your nodes to the network according to the guidance or automation script provided by DECN. This usually includes registering your node, setting network parameters, and possible authentication and authorization processes.`,
      },
      {
        title: "5.Start caching and transmitting data",
        desc: `Once your node is online and becomes part of the network, it will start receiving and responding to content requests. Based on your bandwidth and storage capacity, nodes will cache and transmit data to requesters. These activities will accumulate PIN TOKEN for you based on the incentive mechanism of the network.`,
      },
      {
        title: "6.Monitoring and maintenance",
        desc: `It is necessary to regularly monitor the status of nodes to ensure their optimal efficiency and maximum benefits. This includes checking the health of the hardware, the need for software updates, and the stability of the network connection. Maintenance may also involve reconfiguring software settings or upgrading hardware.`,
      },
    ],
  },

  // 如何領取PIN TOKEN和進行積分交易
  pointsTrading: {
    title: "How to claim PIN TOKEN and conduct point transactions",
    list: [
      {
        title: "1.Claim PIN TOKEN",
        desc: `·Claim pipeline: Node operators participating in the CDN network can claim earned PIN TOKEN rewards in real-time through their personal accounts. These points are direct rewards for providing network resources and services.<br />
          ·Access Account: Node operators need to log in to their account on the DECN platform and view and manage their points through the dashboard.`,
      },
      {
        title: "2.Conduct point trading",
        desc: `·Exchange mechanism: The PIN TOKEN obtained can be exchanged with USDT on DECN's official Swap platform. We have established a direct transaction pair between PIN TOKEN and USDT, allowing users to conveniently and quickly conduct transactions.<br />
          ·Exchange rate: The exchange rate for PIN TOKEN is fixed, that is, 1 CDN=0.4 USDT.<br />
          ·Price protection mechanism: In order to maintain market stability of PIN TOKEN, the official will monitor the market price of PIN TOKEN. If the price drops, the official will take buying measures to support price balance and protect investor interests.`,
      },
      {
        title: "3.Access to trading platforms",
        desc: `·Operation Guide: Users can access DECN's official Swap platform, select the "Redemption" function, enter the desired number of PIN TOKENs to redeem, and the system will automatically calculate and display the corresponding USDT amount. After confirming the accuracy, the user can proceed with the redemption, and the redeemed USDT will be deposited into the user's account.<br />
          ·Safety reminder: It is recommended that users understand the current market situation and potential trading risks before conducting transactions, and arrange trading time and quantity reasonably.`,
      },
    ],
  },

  // 申請超級節點
  superNode: {
    title: "Apply for super node",
    list: [
      {
        title: "Network requirements",
        clist: [
          {
            ctitle: "1.You must have at least 10 sets of network nodes and ensure their high-speed and stable connections.",
            desc: "",
            listArr: {},
          },
          {
            ctitle: "2.Super nodes can expand their networks on their own to meet the growing network demands and traffic.",
            desc: "",
            listArr: {},
          },
          {
            ctitle: "3.Ensure the availability and stability of the network to provide reliable services.",
            desc: "",
            listArr: {},
          },
        ],
      },
      {
        title: "Super node rewards",
        clist: [
          {
            ctitle: "1.Basic rewards:",
            desc: `·When the super node meets the minimum bandwidth requirement, it can receive a basic reward of 0.5-5% of the node's mining machine revenue.<br />
              ·Basic rewards serve as stable returns for super nodes, encouraging them to provide sustained and stable network connections.`,
            listArr: {},
          },
          {
            ctitle: "2.Additional rewards:",
            desc: `·When the bandwidth of the super node exceeds the minimum requirement, additional rewards will be obtained based on the monthly actual computing power ranking.<br />
            ·The additional reward ratio is dynamically adjusted based on the node's computing power ranking and the computing power exceeding the basic demand, ranging from 0.5% to 5% of mining machine revenue.<br />
            ·Additional rewards serve as incentives for nodes to provide more bandwidth and computing power, in order to improve the overall efficiency and stability of the network.`,
            listArr: {},
          },
          {
            ctitle: "3.Punishment mechanism:",
            desc: `·If the super node fails to meet its basic computing power requirements during operation, its reward will be reduced.<br />
              ·If the node does not meet the basic computing power requirements in the second month, the distribution of super node rewards will be suspended until the standard is met again.<br />
              ·This penalty mechanism ensures that the super node always stays above the minimum standard required by the network to maintain network stability and service quality.`,
            listArr: {},
          },
          {
            ctitle: "4.Reward settlement:",
            desc: `·At the end of each month, the bandwidth and computing power of the super node are settled, and corresponding rewards are distributed based on actual contributions.<br />
              ·The settlement process ensures fairness and transparency, and timely payment of deserved rewards to super nodes to motivate them to continue participating in the network.`,
            listArr: {},
          },
        ],
      },
      {
        title: "Specific reward rules",
        clist: [
          {
            ctitle: "1.Network benefits",
            desc: `Network revenue refers to the additional proportion of the total revenue generated by the network equipment that can be obtained by binding devices with accumulated revenue to the account to achieve the target.`,
            listArr: {
              networking: "Rewards",
              "10A": "0.5%",
              "50A": "1%",
              "100A": "1.5%",
              "500A": "2%",
              "1000A": "3%",
              "5000A": "5%",
            },
          },
          {
            ctitle: "2.Space competition ranking reward (50000 PIN TOKEN reward per month)",
            desc: `Reward PIN TOKEN through monthly stable computing power ranking. <br />
            top 1 <br />
            top 2 <br />
            ……<br />
            top 10`,
            listArr: {},
          },
        ],
      },
    ],
  },
};
