export const titleObj = {
  languageTitle: "語言",
  nodeDeployment: "節點部署",
  scriptTitle: "腳本",
  scriptSubTitle: "下載腳本，開始使用",
  homeDecentralizationSubTitle: "Decentralized Edge Computing Infrastructure Network",
  homeDecentralizationTitle: "去中心化邊緣計算基礎設施網絡<span class='title-color margin-left-10'> DECN </span>",
  homeDecentralizationChildSubTitle: "DECN是去中心化計算資源組成的邊緣計算網絡，將計算資源、頻寬資源和存儲資源提供給資源使用者從而獲得網絡激勵的一個基礎設施網絡。",
  homeDecentralizationChildTitle: "什麼是<span class='title-color'> DECN ?</span>",
  homeAdoptTitle: "通過<span class='title-color'> DECN </span>全球網絡實現高性能內容交付",
  homeAdoptSubTitle: "Achieve high-performance content delivery through the DECN global network.",
  homeAdoptSetion: [
    {
      Icon: require("@/assets/images/home_icon_globe.png"),
      img: "",
      title: "現代化數位體驗",
      desc: "項目本身不需要任何質押及繁瑣的申請流程，只需要貢獻節點的存儲計算及網絡。",
    },
    {
      Icon: require("@/assets/images/user-check.png"),
      img: require("@/assets/images/home_img_expansion.png"),
      title: "在邊緣輕鬆拓展",
      desc: "通過在更接近最終用戶的位置處理工作負載來提高效能，助力未來發展。",
    },
    {
      Icon: require("@/assets/images/home_icon_shield.png"),
      img: require("@/assets/images/home_img_secure.png"),
      title: "應用安全無處不在",
      desc: "借助全面的安全控制、統一的策略和可觀察性（包括簡化的跨環境應用安全防護功能部署和管理），緩解Web應用攻擊和漏洞。",
    },
  ],
  homeProductTitle: "產品概覽",
  homeProductSetion: [
    {
      title: "改進跨環境的內容交付",
      desc: "DECN分佈式雲CDN基於高性能且安全的全球專用網路構建，可為最終用戶打造豐富的數位體驗。 分佈式雲CDN與關鍵應用安全服務集成，為企業實施多雲和基於邊緣的計畫提供强有力的支持。",
    },
    {
      title: "DECN 全球PoP節點",
      desc: "從DECN全球網絡上的節點（PoP）管理和保護應用工作負載和緩存內容。",
    },
  ],
  homeCoreTitle: "覈心功能",
  homeCoreSubTitle: "為最終用戶打造豐富的數位體驗，為企業實施多雲和基於邊緣的計畫提供强有力的支持",
  homeCoreSetion: [
    {
      topTitle: "Secure",
      title: "安全的多雲和邊緣應用交付",
      desc: "利用集成式安全防護功能，包括Web應用防火牆（WAF）、分佈式拒絕服務（DDoS）緩解措施、API安全和Bot防禦。 這些功能由專為多雲和基於微服務的邊緣工作負載而構建的平臺提供。",
    },
    {
      topTitle: "Universal",
      title: "全球可用性",
      desc: "通過高容量DECN全球網絡實現高效的全球交付，並利用DECN的DNS和負載等化器服務來均衡跨公有雲和私有雲的多個源服務器之間的流量，從而實現高可用性。",
    },
    {
      topTitle: "Simple",
      title: "集中式控制平面",
      desc: "通過觀察端到端應用流量和事件，降低操作複雜性、優化應用效能並提高應用的安全效率。",
    },
    {
      topTitle: "Optimized",
      title: "易於配寘緩存策略",
      desc: "獲取易於配寘且易於使用的緩存策略。 分佈式雲CDN僅緩存源所要求緩存的內容來避免“偏差”。",
    },
    {
      topTitle: "Stay up-to-date",
      title: "支持現代內容",
      desc: "獲取現代內容類型所需的支持，包括點播視頻、流媒體和網站以及API流量所需的其他內容。 根據檔案類型，優化效能。",
    },
    {
      topTitle: "Cloud",
      title: "雲不可知",
      desc: "受益於跨公有雲和混合雲解決方案的更高靈活性、更少的應用安全防護功能部署限制、更强的流量路由控制以及訓示工作負載部署位置的能力。",
    },
  ],
  homeResourceTitle: "资源",
  homeResourceList: [
    {
      img: require("@/assets/images/home_logo_icdn.png"),
    },
    {
      img: require("@/assets/images/home_logo_ppio.png"),
    },
    {
      img: require("@/assets/images/home_logo_bih.png"),
    },
    {
      img: require("@/assets/images/home_logo_tiktok.png"),
    },
    {
      img: require("@/assets/images/home_logo_iqiyi.png"),
    },
    {
      img: require("@/assets/images/home_logo_youja.png"),
    },
    {
      img: require("@/assets/images/home_logo_keyword.png"),
    },
  ],

  contributeResourcesRewardsTitle: "貢獻你的閒置頻寬來獲取<span class='title-color'> PIN </span>獎勵",
  contributeResourcesWhatTitle: "什么是<span class='title-color'> PIN TOKEN </span>？",
  contributeResourcesWhatSubTitle: "什麼是<span class='title-color'> PIN TOKEN </span>？",
  contributeResourcesWhatSubDesc: "PIN TOKEN是DECN網絡生態系統的貢獻獎勵TOKEN，採用通縮燃燒鑄幣平衡模型（Burn-and-Mint Equivalent，BME）產生。 它用於獎勵、治理、交易和質押。",
  contributeResourcesWhatSubTitle1: "PIN TOKEN經濟模型",
  contributeResourcesWhatSubDesc1:
    "PIN TOKEN總量沒有上限，其產生管道將由合作方消耗DECN網絡資源實際鑄幣。 即：合作方消耗DECN網絡100USDT的費用，需向DECN網絡燃燒支付100USDT的PIN TOKEN，此時市場對應產生100USDT的PIN TOKEN。<br />通縮燃燒鑄幣平衡模型（Burn-and-Mint Equivalent，BME）每個接入網路鑄造係數每年减半，即第一年係數為1，即合作方燃燒一個PIN Token，並向市場鑄造一個PIN Token； 第二年合作方每燃燒一個PIN Token鑄造0.5個PIN Token。",
  contributeResourcesGetTitle: "如何獲得<span class='title-color'> PIN TOKEN </span>？",
  contributeResourcesGetSubTitle: "向DECN網絡提供算力",
  contributeResourcesGetSubTitle2: "通過swap交易獲得",

  dashboardChartTitle: "市值",
  dashboardChartList: [
    {
      title: "交易數",
      value: "61.89M",
      color: "#5686E6",
    },
    {
      title: "活躍地址數",
      value: "180",
      color: "#5686E6",
    },
    {
      title: "總地址數",
      value: "2,660,774",
      color: "#ffffff",
    },
    {
      title: "資產總值",
      value: "3,774",
      color: "#5686E6",
    },
    {
      title: "區塊高度",
      value: "19836369",
      color: "#ffffff",
    },
  ],

  nodeName: {
    // 地图节点名称
    Malaysia: "马来西亚节点",
    Thailand: "泰国节点",
    Indonesian: "印尼节点",
    Philippine: "菲律宾节点",
    Vietnam: "越南节点",
    Japan: "日本节点",
  },

  deploymentNoticeList: [
    {
      title: "DECN代幣分配計畫",
      list: [
        {
          subtitle: "PAGE",
          title: "DECN代幣分配比例",
          mode: "allocationRatio",
        },
        {
          subtitle: "PAGE",
          title: "DECN代幣分配規則",
          mode: "allocationRules",
        },
        {
          subtitle: "PAGE",
          title: "DECN項目路線圖（2023-2025）",
          mode: "projectRoadmap",
        },
      ],
    },
    {
      title: "PIN TOKEN",
      list: [
        {
          subtitle: "PAGE",
          title: "PIN TOKEN的生成與分配",
          mode: "generationAndAllocation",
        },
        {
          subtitle: "PAGE",
          title: "PIN TOKEN的用途",
          mode: "purpose",
        },
        {
          subtitle: "PAGE",
          title: "如何参与PIN TOKEN节点建设",
          mode: "nodeConstruction",
        },
        {
          subtitle: "PAGE",
          title: "如何領取PIN TOKEN和進行積分交易",
          mode: "pointsTrading",
        },
        {
          subtitle: "PAGE",
          title: "申請超級節點",
          mode: "superNode",
        },
      ],
    },
    {
      title: "Ubuntu22.04 LTS 装机教程",
      list: [
        {
          subtitle: "PAGE",
          title: "製作安裝介質",
          mode: "install",
        },
        {
          subtitle: "PAGE",
          title: "把设备绑定到您的账户",
          mode: "binding",
        },
      ],
    },
  ],

  // 节点部署详情--------
  nodeDeploymentMenu: [
    {
      id: "0",
      title: "DECN代幣分配計畫",
      list: [
        {
          cid: "0",
          title: "DECN代幣分配比例",
          mode: "allocationRatio",
        },
        {
          cid: "1",
          title: "DECN代幣分配規則",
          mode: "allocationRules",
        },
        {
          cid: "2",
          title: "DECN項目路線圖（2023-2025）",
          mode: "projectRoadmap",
        },
      ],
    },
    {
      id: "2",
      title: "PIN TOKEN",
      list: [
        {
          cid: "0",
          title: "PIN TOKEN的生成與分配",
          mode: "generationAndAllocation",
        },
        {
          cid: "1",
          title: "PIN TOKEN的用途",
          mode: "purpose",
        },
        {
          cid: "2",
          title: "如何参与PIN TOKEN节点建设",
          mode: "nodeConstruction",
        },
        {
          cid: "3",
          title: "如何領取PIN TOKEN和進行積分交易",
          mode: "pointsTrading",
        },
        {
          cid: "4",
          title: "申請超級節點",
          mode: "superNode",
        },
      ],
    },
    {
      id: "3",
      title: "Ubuntu22.04 LTS 装机教程",
      list: [
        {
          cid: "0",
          title: "製作安裝介質",
          mode: "install",
        },
        {
          cid: "1",
          title: "把设备绑定到您的账户",
          mode: "binding",
        },
      ],
    },
  ],
  // DECN代幣分配比例
  allocationRatio: {
    title: "DECN代幣分配比例",
    list: [
      {
        title: "1.融資（10%）",
        desc: `·目的：這部分代幣用於初期融資活動，支持網絡的啟動和發展。 融資可能通過私募或公募管道進行，吸引早期投資者支持項目。<br />
        ·使用：資金主要用於開發DECN科技基礎設施、初步市場推廣、團隊建設和初期運營成本。`,
      },
      {
        title: "2.DECN實驗室（10%）",
        desc: `·目的：專門用於研發投入，支持DECN實驗室的技術創新和新功能開發。<br />
            ·使用：這些代幣將資助未來的研究專案、科技改進、新算灋的開發以及安全性增强等。  `,
      },
      {
        title: "3.PL團隊及貢獻者（10%）",
        desc: `·目的：獎勵項目的創始團隊和關鍵貢獻者，以認可他們在項目早期階段的努力和貢獻。<br />
        ·使用：確保團隊成員和早期貢獻者的長期參與和激勵，保持他們的積極性和忠誠度。`,
      },
      {
        title: "4.基金會（5%）",
        desc: `·目的：支持DECN基金會的日常運營和管理活動，基金會負責整個網絡的治理和戰略規劃。<br />
        ·使用：資金用於組織社區活動、法律顧問、外部審計、以及其他網路支援和維護任務。`,
      },
      {
        title: "5.生態發展（5%）",
        desc: `·目的：用於擴展和加强DECN生態系統，包括與合作夥伴的合作、社區建設、市場行銷及用戶教育。<br />
        ·使用：激勵開發者和內容創造者使用DECN網絡，資助行銷活動和社區獎勵計畫，增强用戶參與和網絡活躍度。`,
      },
      {
        title: "6.治理挖礦（60%）",
        desc: `·目的：通過治理挖礦機制分發大部分代幣，以鼓勵和獎勵社區成員的參與和對網絡的貢獻。<br />
        ·使用：參與者可以通過維護網絡、參與治理投票、提供資源和完成特定任務來獲得代幣。 這種管道旨在持續激勵網絡的增長和健康運行。`,
      },
    ],
    subTitle: "管理和監督",
    subTitleDesc: "代幣分配將通過智慧合約來執行，以確保透明性和公平性。 所有分配活動將在區塊鏈上進行記錄，以便公開稽核和驗證。",
  },
  // DECN代幣分配規則
  allocationRules: {
    title: "DECN代幣分配規則",
    list: [
      {
        title: "投資者的代幣釋放策略",
        list: [],
        desc: `·分配期限：投資者分配的$DECN代幣將在三年內等額釋放。<br />
          ·釋放機制：$DECN代幣的轉讓限制從初始分配日期後的第13個月開始，每月等額釋放，共分24次釋放直至第36個月結束。<br />
          ·目的：此策略旨在為投資者提供長期激勵，同時防止市場過早飽和，確保代幣價格的穩定。`,
      },
      {
        title: "員工及核心成員的代幣釋放策略",
        list: [],
        desc: `·分配期限：員工和核心成員分配的$DECN代幣將在四年內等額釋放。<br />
        ·釋放機制：$DECN代幣的轉讓限制從初始分配日期後的第13個月開始，每月等額釋放，共分36次釋放直至第48個月結束。<br />
        ·目的：這種方法鼓勵員工和覈心團隊成員的長期承諾與參與，有助於維持團隊的穩定和項目的連續性。  `,
      },
      {
        title: "賺取$DECN的管道",
        desc: "",
        list: [
          {
            subTitle: "1.提供硬體支持：",
            subDesc: `·用戶可以通過向網絡提供硬體資源並維護其可用性來獲得每小時的可用性獎勵。<br />
              ·這鼓勵用戶投入高品質的硬體資源，增强網絡的整體效能和可靠性。 `,
          },
          {
            subTitle: "2.質押$DECN：",
            subDesc: `·用戶可以將$DECN作為抵押品放在網絡中的節點上，以賺取每小時的質押獎勵。<br />
              ·這新增了網絡的安全性，因為質押機制通常與節點的行為正向相關，有助於保證節點的誠實和可靠操作。 `,
          },
          {
            subTitle: "3.燃燒CDN積分換取$DECN：",
            subDesc: `·目的：用於擴展和加强DECN生態系統，包括與合作夥伴的合作、社區建設、市場行銷及用戶教育。<br />
              ·使用：激勵開發者和內容創造者使用DECN網絡，資助行銷活動和社區獎勵計畫，增强用戶參與和網絡活躍度。 `,
          },
        ],
      },
    ],
  },
  // DECN項目路線圖（2023-2025）
  projectRoadmap: {
    title: "DECN項目路線圖（2023-2025）",
    list: [
      {
        year: "2023年",
        yearList: [
          {
            title: "Q2",
            clist: [
              {
                ctitle: "項目定義與策略規劃",
                desc: `·確立項目目標、關鍵里程碑和管理結構。<br />
                ·開始製定技術白皮書和詳細的架構設計。`,
              },
              {
                ctitle: "科技預研與平臺設計",
                desc: `·定義網路通訊協定和節點操作規範。<br />
                      ·完成初步的網絡框架開發。`,
              },
            ],
          },
          {
            title: "Q3",
            clist: [
              {
                ctitle: "開發與測試環境搭建",
                desc: `·搭建開發環境並完成基礎網絡的內部測試。<br />
                  ·邀請初步合作夥伴參與封閉測試。`,
              },
              {
                ctitle: "社區建設與市場推廣",
                desc: `·建立官方社交媒體平臺和社區論壇。<br />
                    ·發佈專案進度更新，吸引早期社區成員。`,
              },
            ],
          },
          {
            title: "Q4",
            clist: [
              {
                ctitle: "公開測試與迴響集成",
                desc: `·發佈DECN網絡的公開測試版本，廣泛收集用戶回饋。 <br />
                ·根據迴響進行必要的網絡調优和問題修復。`,
              },
              {
                ctitle: "拓展合作夥伴網絡",
                desc: `·簽訂關鍵內容和資源提供者的合作協定。<br />
                  ·與行業領導者探討合作可能性，擴展影響力。`,
              },
            ],
          },
        ],
      },
      {
        year: "2024年",
        yearList: [
          {
            title: "Q1",
            clist: [
              {
                ctitle: "測試網階段 ",
                desc: `·啟動DECN網絡的測試網，進行廣泛的效能和安全測試。<br />
                  ·邀請更多的開發者和用戶參與測試，以確保網絡的穩定性和可靠性。<br />
                  ·根據測試結果持續優化網絡架構和功能。`,
              },
              {
                ctitle: "用戶教育與培訓",
                desc: `·開設線上研討會和培訓課程，教育用戶和開發者如何有效使用DECN網絡。<br />
                    ·發佈詳細的技術文檔和使用指南。`,
              },
            ],
          },
          {
            title: "Q2",
            clist: [
              {
                ctitle: "正式網絡啟動準備",
                desc: `·根據Q1的測試網迴響，完成最終的網絡優化和調整。<br />
                    ·準備正式網絡的全球啟動和運營。`,
              },
              {
                ctitle: "市場推廣活動加强",
                desc: `·加大市場推廣力度，確保網絡啟動時的用戶接受度和知名度。<br />
                    ·參與國際性的科技會議和展覽，展示DECN科技和應用案例。`,
              },
            ],
          },
          {
            title: "Q3-Q4",
            clist: [
              {
                ctitle: "正式網絡運行與擴展",
                desc: `·正式上線DECN網絡，監控網絡運行狀態，確保全球服務的可用性。<br />
                    ·持續擴展網路功能和服務，提高用戶體驗和網絡效率。`,
              },
              {
                ctitle: "生態系統建設與合作深化",
                desc: `·强化與行業夥伴的合作關係，共同擴展生態系統。<br />
                  ·鼓勵和支持協力廠商開發者在DECN平臺上創新。`,
              },
            ],
          },
        ],
      },
      {
        year: "2025年",
        yearList: [
          {
            title: "持續增長與創新",
            clist: [
              {
                ctitle: "技術創新與生態發展 ",
                desc: `·持續開發新功能，如增强安全措施、資料處理能力。<br />
                  ·擴大在全球市場的業務範圍，特別是新興市場。`,
              },
              {
                ctitle: "社區與市場關係加强",
                desc: `·增强與用戶和客戶的互動，提供優質的客戶支援，保持高用戶滿意度。<br />
                 ·在行業內外參與和影響相關的標準製定。`,
              },
            ],
          },
        ],
      },
    ],
  },

  // PIN TOKEN的生成與分配
  generationAndAllocation: {
    title: "PIN TOKEN的生成與分配",
    list: [
      {
        title: "積分生成",
        desc: "積分的生成基於對網絡的貢獻度，具體貢獻包括以下幾個方面：",
        clist: [
          {
            ctitle: "1.頻寬貢獻：",
            cdesc: `节点提供的带宽是积分生成的主要因素。更多的带宽贡献意味着更大的能力来支持内容的传输，因此获得更多的积分。`,
          },
          {
            ctitle: "2.資料存儲和處理：",
            cdesc: `為網路存儲和處理數據也是獲取積分的一種管道。 節點可以根據其存儲的數據量和處理的數據量獲得積分。`,
          },
          {
            ctitle: "3.網絡穩定性和可用性：",
            cdesc: `持續提供穩定和可靠的服務的節點通常會獲得額外的積分獎勵，以激勵其維持高品質的網路服務。`,
          },
        ],
      },
      {
        title: "積分分配",
        desc: "積分的分配機制應當確保公平性和激勵相容性，以下是分配策略：",
        clist: [
          {
            ctitle: "1.固定比率分配",
            cdesc: `·定義：節點根據實際貢獻的頻寬和處理能力獲得積分。 這種方法按固定比率獎勵節點，確保貢獻直接與獎勵掛鉤。<br />
                ·示例：例如，每提供1TB的資料傳輸能力，節點可獲得固定數量的PIN TOKEN。`,
          },
          {
            ctitle: "2.動態調整",
            cdesc: `·目的：根據網絡的總需求和負載動態調整積分的分配比率。 這樣可以靈活應對不同的網絡狀態，激勵節點在需求高峰期提供更多資源。<br />
                  ·實施：在網路流量高峰期，系統自動提高積分的發放率，以獎勵那些能够提供額外資源的節點。`,
          },
          {
            ctitle: "3.效能基準",
            cdesc: `·標準設定：設定效能基準，只有達到或超過這些基準的節點才能獲得積分。 這促使節點維護其設備和連接，確保提供高品質的服務。<br />
                ·目的：鼓勵節點運營者投資於設備的維護和陞級，以保持其服務水平符合網絡要求。`,
          },
          {
            ctitle: "4.參與時效",
            cdesc: `·奖励机制：考虑节点在网络中的活跃时间，对长期服务的节点提供额外的积分奖励，以认可其持续贡献和忠诚度。<br />
                ·实施：例如，连续运行一年以上的节点，每月额外获得5%的积分奖励。`,
          },
          {
            ctitle: "5.參與時間",
            cdesc: `·定義：考慮到網路維護和機器的損耗，接入網路的設備從接入當天開始，每6個月其積分獎勵將减半。<br />
              ·恢復機制：參與節點可以通過消耗PIN TOKEN的管道，恢復至初始算力。 這個機制旨在激勵節點不斷更新和維護其硬體，以保持良好的服務質量。`,
          },
        ],
      },
      {
        title: "激勵和懲罰機制",
        desc: "",
        clist: [
          {
            ctitle: "1.激勵：",
            cdesc: `對於表現出色或在網絡中負責重要任務的節點，給予額外的積分獎勵，如對關鍵內容的快速傳輸或處理大量請求的能力。。更多的带宽贡献意味着更大的能力来支持内容的传输，因此获得更多的积分。`,
          },
          {
            ctitle: "2.懲罰：",
            cdesc: `如果節點出現服務中斷或未達到預期的服務水平，可能會扣除其一部分積分，以此作為維持服務質量的強制措施`,
          },
        ],
      },
      {
        title: "稽核和透明度",
        desc: "",
        clist: [
          {
            ctitle: "1.透明的記錄：",
            cdesc: `所有積分的生成和分配記錄公開透明，允許網絡內的所有參與者驗證和監督。`,
          },
          {
            ctitle: "2.定期稽核：",
            cdesc: `定期進行網絡稽核，確保積分分配機制公正無偏，並且按照既定規則執行。`,
          },
        ],
      },
    ],
  },

  // PIN TOKEN的用途
  purpose: {
    title: "PIN TOKEN的用途",
    desc: "PIN TOKEN在分佈式內容交付網絡（DECN）中扮演著多重重要角色，用途涵蓋了激勵機制、交易媒介等多個方面。",
    list: [
      {
        title: "支付網路服務費用",
        desc: `PIN TOKEN最基本的用途是作為支付網絡中的服務費用的媒介。 這包括：`,
        clist: [
          {
            ctitle: "1.頻寬費用：",
            cdesc: `內容提供者需要支付PIN TOKEN來補償那些為其內容傳輸提供頻寬的節點。`,
          },
          {
            ctitle: "2.資料存儲費用：",
            cdesc: `存儲數據也需要資源，囙此，為了在網絡節點上存儲內容，內容提供者可能需要支付相應的PIN TOKEN。`,
          },
          {
            ctitle: "3.增值服務：",
            cdesc: `例如內容分發網絡中可能提供的內容加速、增强安全性、優先順序處理等服務也可以通過PIN TOKEN來購買。`,
          },
        ],
      },
      {
        title: "激勵參與者",
        desc: `PIN TOKEN用作激勵機制，鼓勵網絡參與者（尤其是節點運營者）提供更高質量的服務：`,
        clist: [
          {
            ctitle: "1.效能獎勵：",
            cdesc: `節點根據其提供的頻寬質量、資料處理速度和網絡穩定性來獲得PIN TOKEN獎勵。`,
          },
          {
            ctitle: "2.参与奖励：",
            cdesc: `鼓励新的节点加入网络并提供资源，可以初期给予一定的PIN TOKEN作为启动奖励。`,
          },
        ],
      },
      {
        title: "交易和兌換",
        desc: `PIN TOKEN可以在網絡的內部市場或與其他加密貨幣之間進行交易：`,
        clist: [
          {
            ctitle: "1.兌換為其他貨幣：",
            cdesc: `用戶可以選擇將PIN TOKEN在加密貨幣交易所上兌換為比特幣、乙太坊或甚至是法定貨幣。`,
          },
          {
            ctitle: "2.內部交易：",
            cdesc: `网络中的服务或资源可以通过PIN TOKEN来购买或交换，如用户间的带宽交易等。`,
          },
        ],
      },
      {
        title: "增强网络安全",
        desc: `PIN TOKEN還可以用作保證金或抵押，增强網絡的信譽和安全性：`,
        clist: [
          {
            ctitle: "1.保證金機制：",
            cdesc: `節點為了證明其對網絡的承諾，可能需要存放一定數量的PIN TOKEN作為保證金。`,
          },
          {
            ctitle: "2.防止濫用和欺詐行為：",
            cdesc: `使用PIN TOKEN作为交易的担保，以确保双方履行其在交易中的义务。`,
          },
        ],
      },
    ],
  },

  // 如何参与PIN TOKEN节点建设
  nodeConstruction: {
    title: "如何参与PIN TOKEN节点建设",
    list: [
      {
        title: "1.理解網絡要求",
        desc: `在參與之前，首先需要瞭解網絡對節點的基本要求，這包括硬體設定、網絡連線速度、可用性（uptime）等。 一般而言，一個CDN節點需要有穩定的高速互聯網連接、足够的存儲空間來緩存內容，以及一定的計算能力來處理數據請求。`,
      },
      {
        title: "2.獲取所需硬體",
        desc: `根據網絡的科技要求，購買或準備必要的硬體設備。 這可能包括高性能的服務器、穩定的網路設備（如路由器和交換機）、以及足够的硬碟存儲。 為了保證服務的可靠性，還可能需要考慮電源不間斷系統（UPS）和良好的物理安全措施。`,
      },
      {
        title: "3.安裝和配寘軟件",
        desc: `下載並安裝所需的軟件，這通常包括節點作業系統、網路管理軟件以及特定的CDN軟件。 這些軟件將幫助你連接到CDN網絡，管理資料傳輸和緩存內容。 配寘過程中可能需要設定適當的安全措施，如防火牆和資料加密。`,
      },
      {
        title: "4.連接到網絡",
        desc: `按照DECN提供的指導或自動化腳本，將你的節點連接到網絡。 這通常包括注册你的節點，設定網絡參數，以及可能的身份驗證和授權過程。`,
      },
      {
        title: "5.開始緩存和傳輸數據",
        desc: `一旦你的节点在线并成为网络的一部分，它将开始接收和响应内容请求。根据你的带宽和存储能力，节点将缓存并传输数据给请求者。这些活动将根据网络的激励机制为你积累PIN TOKEN。`,
      },
      {
        title: "6.監控和維護",
        desc: `為了確保節點的最優效能和最大的收益，定期監控節點的狀態是必要的。 這包括檢查硬體的健康狀況、軟件的更新需要，以及網絡連接的穩定性。 維護也可能涉及重新配寘軟件設定或陞級硬體。`,
      },
    ],
  },

  // 如何領取PIN TOKEN和進行積分交易
  pointsTrading: {
    title: "如何領取PIN TOKEN和進行積分交易",
    list: [
      {
        title: "1.領取PIN TOKEN",
        desc: `·領取管道：參與CDN網絡的節點操作者可以實时通過其個人帳戶領取所賺取的PIN TOKEN獎勵。 這些積分是對其提供網絡資源和服務的直接獎勵。<br />
         ·訪問帳戶：節點操作者需登入其在DECN平臺上的帳戶，通過儀錶盤即可查看和管理其積分。`,
      },
      {
        title: "2.進行積分交易",
        desc: `·兌換機制：獲得的PIN TOKEN可在DECN的官方Swap平臺上與USDT進行兌換。 我們設立了PIN TOKEN與USDT的直接交易對，允許用戶方便快捷地進行交易。<br />
          ·兌換比率：PIN TOKEN的兌換比率固定，即1 CDN = 0.4 USDT。<br />
·         ·價格保護機制：為保持PIN TOKEN的市場穩定，官方將監控PIN TOKEN的市場價格。 如果價格出現下跌，官方將採取買入措施，以支持價格平衡並保護投資者利益。`,
      },
      {
        title: "3.交易平臺訪問",
        desc: `·操作指南：用戶可訪問DECN的官方Swap平臺，選擇“兌換”功能，輸入希望兌換的PIN TOKEN數量，系統將自動計算並顯示相應的USDT金額。 確認無誤後，用戶可以執行兌換，兌換的USDT將存入用戶帳戶中。<br />
        ·安全提示：建議用戶在進行交易前，瞭解當前的市場行情和可能的交易風險，合理安排交易時間和數量。`,
      },
    ],
  },

  // 申請超級節點
  superNode: {
    title: "申請超級節點",
    list: [
      {
        title: "網絡要求",
        clist: [
          {
            ctitle: "1.必须拥有至少10组网络节点，并确保节点的高速稳定连接。",
            desc: "",
            listArr: {},
          },
          {
            ctitle: "2.超级节点可自行扩展网络，以满足日益增长的网络需求和流量。",
            desc: "",
            listArr: {},
          },
          {
            ctitle: "3.确保网络的可用性和稳定性，以提供可靠的服务。",
            desc: "",
            listArr: {},
          },
        ],
      },
      {
        title: "超級節點獎勵",
        clist: [
          {
            ctitle: "1.基礎獎勵：",
            desc: `·當超級節點滿足最低頻寬需求時，可獲得節點礦機收益的0.5-5%作為基礎獎勵。 <br />
            ·基礎獎勵作為超級節點的穩定收益，鼓勵節點提供持續穩定的網絡連接。`,
            listArr: {},
          },
          {
            ctitle: "2.額外獎勵：",
            desc: `·超級節點頻寬超過最低需求時，根據每月實際算力排名獲得額外獎勵。  <br />
            ·額外獎勵比例根據節點的算力排名和超出基礎需求部分的算力而動態調整，範圍在0.5%至5%的礦機收益之間。 <br />
            ·額外獎勵作為對節點提供更多頻寬和算力的激勵，以提高網絡的整體效能和穩定性。`,
            listArr: {},
          },
          {
            ctitle: "3.懲罰機制：",
            desc: `·若超級節點運行過程中未能滿足基本算力需求，其獎勵將會降低。<br />
                ·如果節點在第二個月仍未達到基礎算力需求，將暫停分發超級節點獎勵，直至重新達到標準為止。<br />
                ·這一懲罰機制確保超級節點始終保持在網絡要求的最低標準以上，以維護網絡的穩定性和服務質量。`,
            listArr: {},
          },
          {
            ctitle: "4.獎勵結算：",
            desc: `·每個月結束時，對超級節點的頻寬和算力進行結算，並根據實際貢獻發放相應獎勵。<br />
              ·結算過程確保公平、透明，及時向超級節點支付應得的獎勵，以激勵其持續參與網絡。`,
            listArr: {},
          },
        ],
      },
      {
        title: "具體獎勵規則",
        clist: [
          {
            ctitle: "1.組網收益",
            desc: `組網收益，帳號累計綁定有收益的設備達到目標，可獲得組網設備總收益的額外比例的收益。`,
            listArr: {
              "组网 台": "獎勵",
              "10A": "0.5%",
              "50A": "1%",
              "100A": "1.5%",
              "500A": "2%",
              "1000A": "3%",
              "5000A": "5%",
            },
          },
          {
            ctitle: "2.太空競賽排名獎勵（每月5W PIN TOKEN獎勵）",
            desc: `通過每月穩定算力排行進行PIN TOKEN獎勵。 <br />
            top 1 <br />
            top 2 <br />
            ……<br />
            top 10`,
            listArr: {},
          },
        ],
      },
    ],
  },
};
