import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/styles/reset.scss";
// import "element-ui/lib/theme-chalk/display.css";
import "@/utils/animation.js";
import "@/utils/common.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./locals";
import VueScrollTo from "vue-scrollto";

import { screenWidth } from "@/utils/index";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: false,
    button: true,
    navbar: false,
    title: true,
    toolbar: false,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});

screenWidth();
Vue.prototype.$screenModel = (document.body.clientWidth || window.innerWidth) >= 768 ? "pc" : "mobile";
Vue.prototype.$screenWidth = 1920;
Vue.prototype.$language = window.sessionStorage.getItem("lang") || "en";

window.addEventListener("resize", () => {
  Vue.prototype.$screenWidth = window.screen.width || 1920;
  Vue.prototype.$screenModel = (document.body.clientWidth || window.innerWidth) >= 768 ? "pc" : "mobile";
});

const scrollOptions = {
  container: "body", //滚动的容器
  duration: 500, //滚动时间
  easing: "ease", //缓动类型
  offset: 0, // 滚动时应应用的偏移量。此选项接受回调函数
  force: true, // 是否应执行滚动
  cancelable: true, // 用户是否可以取消滚动
  onStart: false, // 滚动开始时的钩子函数
  onDone: false, // 滚动结束时候的钩子函数
  onCancel: false, // 用户取消滚动的钩子函数
  x: false, // 是否要在x轴上也滚动
  y: true, // 是否要在y轴上滚动
};
Vue.use(VueScrollTo, scrollOptions);

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
