const { btnObj } = require("./btnEN.js");
const { titleObj } = require("./titleEN.js");
const { formObj } = require("./formEN.js");
module.exports = {
  ...btnObj,
  ...titleObj,
  ...formObj,
  Language: "English",
  // 头部左边菜单
  menuList: [
    {
      name: "Home",
      path: "home",
      children: [],
      isChildMenu: false,
    },
    {
      name: "Contribute Resources",
      path: "contribute-resources",
      children: [],
      isChildMenu: false,
    },
    {
      name: "Dashboard",
      path: "dashboard",
      key: "dashboard",
      isOpen: false,
      isChildMenu: true,
      children: [
        // {
        //   name: "账户系统",
        //   key: "account-system",
        //   path: "account-system",
        //   children: [],
        // },
        {
          name: "Dashboard",
          key: "dashboard",
          path: "dashboard",
          children: [],
        },
        {
          name: "Deployment Notice",
          path: "deployment-notice",
          key: "deployment-notice",
          children: [],
        },
      ],
    },
    // {
    //   name: "Industry",
    //   path: "industry",
    // },
    // {
    //   name: "Role",
    //   path: "role",
    // },
    // {
    //   name: "Partners",
    //   path: "partners",
    // },
    // {
    //   name: "Request",
    //   path: "request",
    // },
    // {
    //   name: "Documentation",
    //   path: "document",
    // },
    // {
    //   name: "Script",
    //   path: "script",
    // },
  ],

  // 空閒網絡賺取收益
  // 空閒網絡賺取收益
  spaceNetworkUp: "Upload",
  spaceNetworkDown: "Download",
  spaceNetworkMemory: "Memory",
  spaceNetworkMemoryRate: "Usage",
  spaceNetworkCpu: "CPU",
  spaceNetworkCpuRate: "CPU",

  // 真正的乾淨
  clean: [
    {
      title: "Easy To Apply For",
      desc: "The project itself does not require any collateral or cumbersome application process, only the storage, calculation, and network of contribution nodes.",
    },
    {
      title: "Idle Resource Utilization",
      desc: "We only utilized everyone's idle electronic devices, computers, and networks to demonstrate value.",
    },
    {
      title: "A Clean Blockchain Project",
      desc: "We are a very clean blockchain project that does not require anyone's ICO or any donations.",
    },
    {
      title: "Commitments",
      desc: "Anything that doesn't match what we say is considered a scammer.",
    },
  ],

  // 行業
  industry: [
    {
      topTitle: "Game",
      title: "Overseas Game Acceleration",
      desc: "The game acceleration solution utilizes CDN technology to distribute game content across servers in different geographical locations, achieving faster access speeds. This solution can accelerate game access speed, enhance user experience, reduce server pressure, improve website security, and support loading of large file resources.",
    },
    {
      topTitle: "Video",
      title: "Video Acceleration",
      desc: "The CDN video acceleration solution utilizes CDN technology to distribute video content to node servers close to users, in order to improve the smoothness and response speed of video playback. This plan can reduce the pressure on the source station, ensure stability, and provide safety protection.",
    },
    {
      topTitle: "WEB 3.0",
      title: "WEB 3.0",
      desc: "The WEB 3.0 solution is based on blockchain technology and is committed to building a more secure, transparent, and decentralized internet. This scheme achieves data autonomy and privacy protection through technologies such as smart contracts, decentralized storage, and distributed identity authentication, while improving data security and credibility.",
    },
    {
      topTitle: "Artificial intelligence",
      title: "Artificial Intelligence",
      desc: "The AI computing power scheduling platform is a solution for AI computing infrastructure, which can achieve unified integration and management of multiple computing power platforms, and can flexibly deploy and schedule between multiple AI computing power resource pools according to different applications, models, and resource requirements.",
    },
  ],

  // 要求
  request: [
    {
      title: "Raspberry Pi",
      desc: "3318 3328 3568  S905 H616  H618 ",
      list: [],
    },
    {
      title: "Network",
      desc: "Public IP and NAT0",
      list: [],
    },
    {
      title: "Personal Computer",
      desc: "",
      list: [
        {
          title: "CPU",
          desc: "4 cores and above",
        },
        {
          title: "DDR",
          desc: "4G and above",
        },
        {
          title: "Storage",
          desc: "128G and above",
        },
      ],
    },
  ],

  // 脚本
  script: [
    {
      title: "Mirror script",
      url: "https://drive.google.com/file/d/1-5PDjXD1nTBJLzT1idqG0_2pVVbgk2RN/view?usp=drive_link",
      icon: require("@/assets/images/icon_mirror.png"),
    },
    {
      title: "Network Check Plugin",
      url: "https://app.dcdn.plus/tools/nattypechecker.exe",
      icon: require("@/assets/images/icon_plugin.png"),
    },
    {
      title: "APP",
      url: "https://app.dcdn.plus/app/decn.apk",
      iosUrl: "http://h5.dcdn.plus",
      androidUrl: "https://app.dcdn.plus/app/decn.apk",
      icon: require("@/assets/images/icon_app.png"),
    },
    {
      title: "Web browsing",
      url: "http://h5.dcdn.plus",
      icon: require("@/assets/images/icon_web_56.png"),
    },
  ],

  // 文档
  doc: {
    menu: [
      {
        id: "0",
        title: "Create installation media",
        mode: "install",
      },
      {
        id: "1",
        title: "Bind the machine to your own account",
        mode: "binding",
      },
    ],
    install: {
      title: "Create installation media",
      SoftwareRequirementsTitle: "The software requirements are as follows:",
      SoftwareRequirementsAttention: "Note: Before installing the ubuntu image on your machine, you must ensure that the machine you installed has been connected to the Internet.",
      DownloadAddress: "Download address:",
      DownloadAddressUrl: "https://www.ventoy.net/en/download.html",
      DownloadDirectly: "Download directly：",
      MirrorAddress: "Ubuntu image download address:",
      MirrorAddressIntroduce: "Prepare a USB drive which has more larger than 4GB capacity and a computer.",
      InstallationIntroductionTitle: "Create installation media.",
      InstallationIntroductionAttention:
        "Note: Insert your USB drive into the computer and confirm that the computer can correctly recognize it, then backup the data inside the USB drive (Because  your USB flash disk will be formated next ).",
      Step1Title: "1. Make a media for installing “ubuntu-22.04”.",
      Step1Introduce: "Download the two packages from the previous step on your computer first.Then take the following steps.",
      Step1Item1: "Umcompress “ventoy-1.0.97-windows.zip” ,Then enter the directory ventoy-1.0.97-windows.zip is uncompressed to.",
      Step1Item2: "Double click Ventoy2Disk.exe.",
      Step1Item3: "Click Install.",
      Step1Item4: "Click OK.",
      Step1Item5: "Click Yes.",
      Step1Item6: "Wait for completion.",
      Step1Item7: "After completion.",
      Step1Item8: "Your USB drive could be found on your computer, if you find two logic partitions, the one with the largest capacity is the root directory of our USB flash disk.",
      Step1Item9: "Copy the image of 14-ubuntu-22.04-autoinstall.iso to the root directory of the USB drive.",
      Step1Item10: "Eject USB flash disk",
      Step2Title: "2. Install ubuntu-22 on the computer.",
      Step2Introduce: "Remove the USB drive and insert it into the computer the image will be instaled onl.",
      Step2Item1:
        "(1) After booting the computer, choose a USB drive as the first boot disk. How to enter the choosing screen? Just press F2,F11 or F12.Computers produced by different manufacturers may be different. My computer is F11.",
      Step2Item2: "(2) Select the USB boot device the image has been burned to, wait for a while and the following image will appear.",
      Step2Item3: "(3) Move the cursor to the line below “Use an entire disk” and click enter to enter into the following screen to choose the disk the image will be installed on.",
      Step2Item4: "(4) Move the cursor to the disk the image will be installed on, then press enter.",
      Step2Item5: "(5) Then move the cursor to Done button below, the press Enter.  Then wait for a while and enter into a new screen.",
      Step2Item6: "(6) Use the down arrow key to move the cursor to the Down button and click enter key. Then the installation starts.",
      Step2Item7: "(7) When the installation is completed, move the cursor to the Reboot Now and then press enter.",
      Step2Item8: "(8) After this interface appears, unplug the USB drive and press enter key.Then the computer will be rebooted.",
      Step2Item9: "(9) After the computer has been rebooted, a QR code will appear. Use the app to scan the QR code and bind the computer to your account.",
    },
    binding: {
      title: "How to Bind the machine to your own account?",
      SoftwareRequirementsTitle: "Open the decn app.Then take the following steps：",
      Step1Item1: "Go to the 'Me' screen, click the button “Add Device”.",
      Step1Item2: "Enter the following screen,click<i class='scan-code'></i>and scan the QR code.",
      Step1Item3: "Enter the device name and select your agent.",
      Step1Item4:
        "When the step 3 is completed,the Button Determine becomes colored. Then you can click the button Determine to bind the computer to your account. Then you can find the machine in your account.",
    },
  },
};
