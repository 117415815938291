const { btnObj } = require("./btnZh.js");
const { titleObj } = require("./titleZh.js");
const { formObj } = require("./formZh.js");
module.exports = {
  ...btnObj,
  ...titleObj,
  ...formObj,
  Language: "繁體中文",
  // 头部左边菜单
  menuList: [
    {
      name: "首页",
      path: "home",
      children: [],
      isChildMenu: false,
    },
    {
      name: "貢獻資源",
      path: "contribute-resources",
      children: [],
      isChildMenu: false,
    },
    {
      name: "Dashboard",
      path: "dashboard",
      key: "dashboard",
      isOpen: false,
      isChildMenu: true,
      children: [
        // {
        //   name: "账户系统",
        //   key: "account-system",
        //   path: "account-system",
        //   children: [],
        // },
        {
          name: "Dashboard",
          key: "dashboard",
          path: "dashboard",
          children: [],
        },
        {
          name: "部署须知",
          path: "deployment-notice",
          key: "deployment-notice",
          children: [],
        },
      ],
    },

    // {
    //   name: "行業",
    //   path: "industry",
    // },
    // {
    //   name: "作用",
    //   path: "role",
    // },
    // {
    //   name: "同伴",
    //   path: "partners",
    // },
    // {
    //   name: "要求",
    //   path: "request",
    // },
    // {
    //   name: "文檔",
    //   path: "document",
    // },
    // {
    //   name: "腳本",
    //   path: "script",
    // },
  ],

  // 空閒網絡賺取收益
  spaceNetworkUp: "上行網速",
  spaceNetworkDown: "下行網速",
  spaceNetworkMemory: "內存",
  spaceNetworkMemoryRate: "利用率",
  spaceNetworkCpu: "CPU",
  spaceNetworkCpuRate: "錄用率",

  // 真正的乾淨
  clean: [
    {
      title: "易申請",
      desc: "項目本身不需要任何質押及繁瑣的申請流程，只需要貢獻節點的存儲計算及網絡。",
    },
    {
      title: "利用空閒資源",
      desc: "我們只是利用了大家閑置的電子設備及電腦和閑置的網絡來進行價值的體現。",
    },
    {
      title: "乾淨的區塊鏈項目",
      desc: "我們是壹個非常幹淨的區塊鏈項目，不需要大家的ICO及任何捐助。",
    },
    {
      title: "我們承諾",
      desc: "但凡有出現任何和我們說的不符的地方，都認定爲騙子。",
    },
  ],

  // 行業
  industry: [
    {
      topTitle: "Game",
      title: "海外遊戲加速",
      desc: "海外遊戲加速方案通過使用CDN技術，將遊戲內容分布在不同地理位置的服務器上，以實現更快的訪問速度。該方案可以加速海外遊戲的訪問速度、增強用戶體驗、減少服務器壓力、提高網站安全性、支持搜索引擎優化以及支持大文件資源加載等優點。通過使用CDN海外遊戲加速方案，可以有效提高遊戲的性能和穩定性，爲用戶提供更加優質的遊戲體驗。",
    },
    {
      topTitle: "Video",
      title: "視頻加速",
      desc: "CDN視頻加速解決方案利用CDN技術，將視頻內容分發至靠近用戶的節點服務器，以提升視頻播放的流暢度和響應速度。此方案可減輕源站壓力，保障穩定性，同時提供安全防護。用戶可快速、穩定地獲取視頻內容，提升觀看體驗。CDN服務商提供靈活的計費方式和優化的用戶體驗，滿足各種視頻應用場景的需求。",
    },
    {
      topTitle: "WEB 3.0",
      title: "WEB 3.0",
      desc: "WEB 3.0解決方案基于區塊鏈技術，致力于構建更安全、透明和去中心化的互聯網。該方案通過智能合約、去中心化存儲和分布式身份認證等技術，實現數據自主權和隱私保護，同時提高數據安全性和可信度。WEB 3.0解決方案還注重用戶參與和社區建設，通過DAO（去中心化自治組織）等模式，讓用戶擁有更多的自主權和收益權，推動互聯網向更加公平、開放和自由的方向發展。",
    },
    {
      topTitle: "Artificial intelligence",
      title: "AI人工智能",
      desc: "AI算力調度平台是壹個面向AI算力基礎設施的解決方案，可實現多種算力平台統壹融合和管理，並能根據不同的應用、模型和資源需求，在多個AI算力資源池之間進行靈活的部署和調度。通過AI算力調度平台，可以實現AI算力的服務化、場景化、自動化，從而加速AI算力資源的商業化進程，提升AI算力的使用率和便捷性。",
    },
  ],

  // 要求
  request: [
    {
      title: "樹莓派",
      desc: "3318 3328 3568  S905 H616  H618 ",
      list: [],
    },
    {
      title: "網絡",
      desc: "公網IP 及 NAT0",
      list: [],
    },
    {
      title: "PC 個人計算機",
      desc: "",
      list: [
        {
          title: "CPU",
          desc: "4核及以上",
        },
        {
          title: "DDR",
          desc: "4G及以上",
        },
        {
          title: "存儲",
          desc: "128G及以上",
        },
      ],
    },
  ],

  // 脚本
  script: [
    {
      title: "鏡像",
      url: "https://drive.google.com/file/d/1-5PDjXD1nTBJLzT1idqG0_2pVVbgk2RN/view?usp=drive_link",
      icon: require("@/assets/images/icon_mirror.png"),
    },
    {
      title: "網絡檢查插件",
      url: "https://app.dcdn.plus/tools/nattypechecker.exe",
      icon: require("@/assets/images/icon_plugin.png"),
    },
    {
      title: "APP",
      url: "https://app.dcdn.plus/app/decn.apk",
      iosUrl: "http://h5.dcdn.plus",
      androidUrl: "https://app.dcdn.plus/app/decn.apk",
      icon: require("@/assets/images/icon_app.png"),
    },

    {
      title: "網頁浏覽地址",
      url: "http://h5.dcdn.plus",
      icon: require("@/assets/images/icon_web_56.png"),
    },
  ],

  // 文档
  doc: {
    menu: [
      {
        id: "0",
        title: "製作安裝介質",
        mode: "install",
      },
      {
        id: "1",
        title: "把设备绑定到您的账户",
        mode: "binding",
      },
    ],
    install: {
      title: "製作安裝介質",
      SoftwareRequirementsTitle: "軟件需求如下:",
      SoftwareRequirementsAttention: "注意：在通過U盤安裝系統前,必須先確保妳安裝的機器已接入互聯網。",
      DownloadAddress: "下載地址：",
      DownloadAddressUrl: "https://www.ventoy.net/cn/download.html",
      DownloadDirectly: "直接下載：",
      MirrorAddress: "Ubuntu鏡像下載地址：",
      MirrorAddressIntroduce: "準備一個大於4GB容量的USB驅動器和一臺電腦。",
      InstallationIntroductionTitle: "製作安裝介質。",
      InstallationIntroductionAttention: "注意：將妳的U盤插入到電腦,並確認電腦能正確識別到U盤,然後將U盤裏面的數據備份好(因爲下壹步會對妳的U盤進行格式化)。",
      Step1Title: "1.製作用於安裝“ubuntu-22.04”的介質。",
      Step1Introduce: "首先在您的電腦上下載上一步中的兩個套裝軟體。然後跟著下圖的步驟操作。",
      Step1Item1: "解壓“ventoy-1.0.97-windows.zip”，然後進入ventoy-1.0.97-windows.zip 解壓目錄。",
      Step1Item2: "雙擊 Ventoy2Disk.exe。",
      Step1Item3: "點擊 Install。",
      Step1Item4: "點擊 OK。",
      Step1Item5: "點擊 Yes。",
      Step1Item6: "等待安裝完成。",
      Step1Item7: "安裝完成。",
      Step1Item8: "你可以在你的電腦上找到U盤，如果出現兩個U盤，選擇其中內存顯示最大的。",
      Step1Item9: "將鏡像文件 14-ubuntu-22.04-autoinstall.iso 複製到USB驅動器的根目錄中。",
      Step1Item10: "彈出U盤。",
      Step2Title: "2.在電腦上安裝ubuntu-22。",
      Step2Introduce: "拔掉U盤,然後將U盤插到妳想安裝的電腦主機上。",
      Step2Item1: "(1) 啟動電腦後，選擇USB盤作為第一個啟動盤。 如何進入選擇荧幕？ 只需按F2、F11或F12。不同製造商生產的電腦可能不同。 我的電腦是F11。",
      Step2Item2: "(2) 選中USB啓動後等待壹會就會出現壹下圖。",
      Step2Item3: "(3) 將光標移動到“使用整個磁片”下麵的行，然後按一下回車進入以下荧幕，選擇要安裝映射的盤。",
      Step2Item4: "(4) 將光標移動到要安裝影像的盤，然後按enter鍵。",
      Step2Item5: "(5) 然後將光標移動到下麵的“Done”按鈕，然後按Enter鍵。 然後等待一段時間，然後進入一個新荧幕。",
      Step2Item6: "(6) 使用向下箭頭鍵將光標移動到向下按鈕，然後按一下回車鍵。 然後開始安裝。",
      Step2Item7: "(7) 安裝完成後，將光標移動到“Reboot Now”，然後按Enter鍵。",
      Step2Item8: "(8) 出現此介面後，拔下U盘並按Enter鍵。然後重启電腦。",
      Step2Item9: "(9) 重新啟動電腦後，會出現一個二維碼。使用APP掃描二維碼並將電腦綁定到您的账户。",
    },
    binding: {
      title: "如何把设备绑定到您的账户？",
      SoftwareRequirementsTitle: "打開decn應用程序。然後按以下步驟：",
      Step1Item1: "進入'Me'界面, 點擊按鈕 “Add Device”.",
      Step1Item2: "進入如題界面，點擊<i class='scan-code'></i>然後掃描二維碼。",
      Step1Item3: "輸入裝置名稱並選擇您的代理。",
      Step1Item4: "當步驟3完成時，按鈕“Determine”會變為彩色。 然後按一下“Determine”按鈕將電腦綁定到您的帳戶。 然後你可以在你的帳戶中找到這台機器。",
    },
  },
};
