export const btnObj = {
  need: "我有需求",
  contributeResources: "貢獻資源",
  contributeYourResources: "貢獻你的資源",
  DetailedDescription: "詳細說明",
  SWAP: "SWAP",
  download: "下載",
  jumpTo: "跳轉到",
  ios: "IOS",
  android: "Android",
};
